import { BuildVersion, EnvironmentModel } from './environment-model.interface';

export const environment: EnvironmentModel = {
  appId: 'com.apk.getmynudes',
  appName: 'GetMyNudes',
  gameName: 'Get My Nudes',
  production: true,
  apiUrl: 'https://api.getmynudes.com',
  buildVersion: BuildVersion.default,
  webSiteUrl: 'https://getmynudes.com',
  discordLink: 'https://discord.gg/3UNBKCX2ZH',
  rootPath: '',
  mode: 'prod',
  showTncs: true,
  allowRegistrationForm: true,
  allowPaymentIframe: true,
  isPromoWidgetChat: true,
  showAdmobAds: false,
  showOnlineWebcamNotification: true,
  splashScreenImgSrc: 'assets/imgx/splash-bg.png',
  firebaseConfig: {
    apiKey: 'AIzaSyCchXDxWly2G8Pf2pyWwU49SxxRo3rZ12A',
    authDomain: 'getmynudes-d4d83.firebaseapp.com',
    projectId: 'getmynudes-d4d83',
    storageBucket: 'getmynudes-d4d83.appspot.com',
    messagingSenderId: '215449707370',
    appId: '1:215449707370:web:1404c575674bc96385bb13',
    measurementId: 'G-3PN0MYE8RD',
  },
  policy: {
    name: 'GetMyNudes',
    url: 'https://getmynudes.com/',
    site: 'GetMyNudes.com',
    docsBaseUrl: 'https://info.getmynudes.com/legal/',
    email: 'info@getmynudes.com',
  },
  promoCodeIsAvailable: true,
  appMetricaKey: '176d7169-8aa4-41da-bc1a-7fa4e9900f11',
  banners: [
    {
      img: '/assets/imgx/stripchat-banners/451_338x235_en_1.gif',
      url: '',
    },
    {
      img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_1.gif',
      url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533',
    },
    {
      img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_2.gif',
      url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533',
    },
    {
      img: '/assets/imgx/stripchat-banners/447_338x235_en_5.gif',
      url: '',
    },
    {
      img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_3.jpg',
      url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533',
    },
    {
      img: '/assets/imgx/stripchat-banners/445_338x235_en_7.gif',
      url: '',
    },
    {
      img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_4.jpg',
      url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533',
    },
    {
      img: '/assets/imgx/stripchat-banners/443_338x235_en_9.gif',
      url: '',
    },
    {
      img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_2.gif',
      url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533',
    },
    {
      img: '/assets/imgx/stripchat-banners/441_338x235_en_11.gif',
      url: '',
    },
    {
      img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_1.gif',
      url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533',
    },
    {
      img: '/assets/imgx/stripchat-banners/439_338x235_en_13.gif',
      url: '',
    },
    {
      img: '/assets/imgx/jerkmatelive-banners/jerkmatelive_338x235_en_4.jpg',
      url: 'https://t.ajrkm1.com/214122/6224/17111?aff_sub=rotation&bo=2779,2778,2777,2776,2775&source=rotate&po=6533',
    },
  ],
};
